import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Infoproser - Rastreo Satelital
			</title>
			<meta name={"description"} content={"Conozca la ubicación de sus activos en todo momento"} />
			<meta property={"og:title"} content={"Infoproser - Rastreo Satelital"} />
			<meta property={"og:description"} content={"Conozca la ubicación de sus activos en todo momento"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16:53:10.740Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65c8fb20926d910020eca277/images/favicon.ico.png?v=2024-02-11T16:52:43.846Z"} />
			<meta name={"msapplication-TileColor"} content={"rgba(0, 8, 72, 0.02)"} />
		</Helmet>
		<Image
			position="absolute"
			bottom="auto"
			md-width="600px"
			md-top="300px"
			lg-right="-200px"
			lg-top="0px"
			src="https://uploads.quarkly.io/6123949e957019001e284458/images/backgroung-hero.svg?v=2021-09-21T22:07:12.314Z"
			right="0px"
			top="130px"
			z-index="1"
		/>
		<Section padding="36px 0 36px 0" quarkly-title="Header">
			<Override slot="SectionContent" flex-direction="row" />
			<Box
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="30%"
				display="flex"
				align-items="center"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				sm-width="50%"
			>
				<Image
					height="auto"
					src="https://uploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16:53:10.740Z"
					width="100%"
					max-width="300px"
					flex="1 1 0%"
					srcSet="https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c8fb20926d910020eca277/images/logo-oscuro.png?v=2024-02-11T16%3A53%3A10.740Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="linear-gradient(264.47deg, #000848 29.39%, #000848 93.49%)" padding="36px 0 80px 0" quarkly-title="HeroBlock">
			<Override
				slot="SectionContent"
				md-z-index="7"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
			/>
			<Box
				empty-border-width="1px"
				align-items="flex-start"
				md-width="100%"
				display="flex"
				flex-direction="column"
				empty-border-color="LightGray"
				width="70%"
				justify-content="center"
				padding="0px 180px 0px 0px"
				lg-padding="0px 90px 0px 0px"
				empty-min-height="64px"
				empty-border-style="solid"
				empty-min-width="64px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					font="--base"
					color="--grey"
					text-transform="uppercase"
					lg-text-align="left"
					lg-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 8px 0px"
					lg-margin="0px 0px 8px 0px"
					sm-font="normal 500 16px/1.6 &quot;Inter&quot;, sans-serif"
					letter-spacing="0.1em"
				>
					No encontramos lo que Buscas #error 404
				</Text>
				<Text
					letter-spacing="1%"
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline1"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
				>
					Estamos Mejorando
				</Text>
				<Text
					opacity="0.7"
					font="--base"
					letter-spacing="0.05em"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
				>
					Te pedimos disculpas por los inconvenientes, nuestro equipo esta trabajando para solucionarlos.
				</Text>
			</Box>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				display="flex"
				md-width="100%"
				empty-border-width="1px"
				empty-border-style="solid"
				width="30%"
				align-items="center"
				justify-content="flex-end"
				md-order="-1"
			>
				<Image
					lg-max-height="400px"
					width="100%"
					max-height="450px"
					md-margin="0px 0px 30px 0px"
					src="https://uploads.quarkly.io/6123949e957019001e284458/images/logos_foundation.svg?v=2021-10-05T09:15:03.700Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
				/>
			</Box>
		</Section>
		<Box
			empty-min-width="64px"
			empty-min-height="64px"
			empty-border-width="1px"
			empty-border-style="solid"
			empty-border-color="LightGray"
			background=",--color-indigo url(https://uploads.quarkly.io/6123949e957019001e284458/images/backfooter.svg?v=2021-08-24T19:40:13.860Z) 0% 0% /cover repeat scroll padding-box"
			min-height="220px"
			md-min-height="140px"
		/>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});